import { loadRemoteEntry } from '@angular-architects/module-federation';

const camelize = (s: string) => s.replace(/-./g, x => x.toUpperCase()[1]);

const setRoutes = (routes: any) => {
  window['remoteRoutes'] = routes;
  return routes;
};

const fetchRemoteEntries = (microFrontends: any) => {
  Promise.all(
    microFrontends
      .uniqBy((microFrontend: any) => microFrontend.frontend)
      .map((microFrontend: any) => {
        return loadRemoteEntry(`/web/${microFrontend.frontend}/frontend/remoteEntry.js`, camelize(microFrontend.frontend))
          // eslint-disable-next-line no-console
          .catch((err: any) => console.log(`Error loading remote entry ${microFrontend.frontend}`, err));
      }));
};

const getMicroFrontends = fetch('/web/services/routes')
  .then(response => {
    if (!response.ok) {
      throw new Error(`route response returned ${response.status}: ${response.statusText}`);
    }
    return response.json();
  })
  .then(setRoutes)
  .then(fetchRemoteEntries)
  .catch(err => {
    // eslint-disable-next-line no-console
    console.error('Error while getting micro frontend routes', err);
    setRoutes([]);
  });

getMicroFrontends.then(() => import('./bootstrap'))
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
